<template>
  <div class="content-wrapper row no-gutters">
    <section class="content-header">
      <div class="container-fluid mt-6">
        <div class="row mb-2 ml-0 ml-sm-12">
          <div class="col-sm-6 ">
            <h1>{{ $t('PRICE_LIST.price') }}</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"
                ><a href="#">{{ $t('PRICE_LIST.main') }}</a></li
              >
              <li class="breadcrumb-item active">{{
                $t('PRICE_LIST.price')
              }}</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <div class="d-flex justify-content-between">
      <div class="col-3 py-0 empty-space"></div>
      <div class="col-12 col-sm-9 py-0">
        <div class="right_b px-2 py-0">
          <ProductListItem
            v-for="{ reyestr, id } in priceList?.data"
            :item="reyestr"
            :key="id"
            @click.native="
              $router.push({
                name: 'price list item',
                params: { id: id },
              })
            "
          />
        </div>

        <nav class="mb-16" aria-label="Page navigation example">
          <ul class="pagination justify-content-end">
            <li class="page-item disabled">
              <a
                class="page-link"
                href="#"
                @click.prevent="goToPage(currentPage - 1)"
                tabindex="-1"
                >Previous</a
              >
            </li>
            <li
              v-for="(page, index) in Array.from(
                {
                  length: getTotalPages(priceList?.to, priceList?.per_page),
                },
                (_, index) => index + 1
              )"
              :key="index"
              class="page-item"
              ><a class="page-link" @click.prevent="goToPage(page)" href="#">{{
                page
              }}</a></li
            >
            <li class="page-item">
              <a class="page-link" @click.prevent="goToPage(2)" href="#"
                >Next</a
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ProductListItem from '../content/ProductListItem.vue'
const { mapState, mapActions } = createNamespacedHelpers('priceList')
export default {
  name: 'PriceList',
  components: {
    ProductListItem,
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 3,
      itemsPerPage: 10,
    }
  },
  computed: {
    ...mapState(['priceList']),
    ...mapActions(['fetchPriceList']),
  },
  methods: {
    goToPage(page) {
      if (
        page >= 1 &&
        page <= this.getTotalPages(this.priceList?.to, this.priceList?.per_page)
      ) {
        this.currentPage = page
        this.fetchPriceList(this.currentPage) // Выполняем запрос на сервер при смене страницы
      }
    },
    getTotalPages(total, perPage) {
      if (total <= perPage) {
        return 1
      }
      return Math.floor(total / perPage)
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: Alegreya Sans;
  src: url('/fonts/alegreyasans/Alegreyasans.ttf') format('truetype');
}
a,
a:hover {
  color: black;
}
ul {
  padding: 0;
  margin: 0;
}

.empty-space {
  display: block;
}
@media (max-width: 575px) {
  .empty-space {
    display: none;
  }
}
.card-body {
  font-family: 'Alegreya sans';
}

.cat__item {
  border: 1px solid #e4f1e3;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 15px;
  margin-bottom: 10px;
}
.cat__name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 13px;
}
.cat__list {
  list-style: none;
  padding-left: 10px;
}

/*range reset color*/
.irs--flat .irs-bar,
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-handle > i:first-child {
  background-color: #84c897;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: #84c897;
}

/*radio*/
.rad-label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  margin-bottom: 3px;
  transition: 0.3s;
}

.rad-label:hover .rad-text {
  color: black;
}

.rad-input {
  width: 0px;
  height: 0px;
  opacity: 0;
  z-index: -1;
}

.right_b {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: fit-content;
  @media (max-width: 896px) {
    width: 100%;
  }

  .price_right {
    background: #ffffff;
    border-radius: 2px;
    margin-bottom: 0.5rem;
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    @media (max-width: 541px) {
      overflow: scroll;
    }
    @media (max-width: 481px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .p_img {
      img {
        width: 100%;
      }
    }

    .p_right_t {
      @include ftz(1rem, 500, #423f3d);
      text-transform: capitalize;
    }

    .p_r_sum {
      @include ftz(0.875rem, 500, #423f3d);
      text-transform: capitalize;

      .p_r_sum_t {
        @include ftz(0.625rem, 500, #423f3d);
        text-transform: uppercase;
        width: 53px;
        height: 14px;
        background: #e8d36a;
        border-radius: 2px;
        margin-left: 8px;
        padding: 3px 6px;
      }
    }

    .about_p {
      @include ftz(0.75rem, 400, #666666);
      margin-bottom: 0.5rem;
      padding-top: 12px;
    }

    .btn_wholesale {
      .o_wholesale,
      .o_by_shop {
        width: 88%;
        height: 3rem;
        background: #cfebcc;
        border-radius: 2px;
        margin-top: 24px;
        margin-bottom: 7px;
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        @media (max-width: 481px) {
          width: 100%;
        }
      }

      .wholesale,
      .by_shop {
        @include ftz(0.625rem, 500, #666666);
        text-transform: uppercase;
        letter-spacing: 0.13em;
        padding-top: 5px;

        @media (max-width: 668px) {
          @include ftz(0.5rem, 500, #666666);
        }
      }

      .customisation,
      .for_order {
        @include ftz(0.625rem, 500, #9cc298);
        text-transform: uppercase;
        letter-spacing: 0.13em;
        text-align: center;

        @media (max-width: 668px) {
          @include ftz(0.5rem, 500, #666666);
        }
      }

      .o_by_shop {
        border: 1px solid #c5dfc2;
        background: transparent;
        margin: 0;
      }
    }
  }
}

.rad-design {
  width: 15px;
  height: 15px;
  border-radius: 50px;

  /*background: linear-gradient(to right bottom, hsl(154, 97%, 62%), hsl(225, 97%, 62%));*/
  background: linear-gradient(
    to right bottom,
    hsl(137deg 38% 65%),
    hsl(217deg 15% 90%)
  );
  position: relative;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  /*transform: scale(1.1);*/
  margin-bottom: 3px;
  transition: 0.3s;
}

.rad-input:checked + .rad-design::before {
  transform: scale(0);
}

.rad-text {
  font-weight: normal;
  margin-left: 14px;
}

.rad-input:checked ~ .rad-text {
  color: black;
  font-weight: 600;
}

.check-ed i {
  display: none;
  opacity: 0.3;
}
.multiselect .rad-input:checked ~ .circle {
  border: 1px solid black;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 30px;
  background: #e6e6e6;
  display: inline-block;
}

.blue {
  background-color: rgba(73, 96, 124, 1);
}
.black {
  background-color: rgba(60, 58, 58, 1);
}
.grey {
  background-color: rgba(140, 139, 140, 1);
}
.green {
  background-color: rgba(105, 113, 94, 1);
}
.orange {
  background-color: rgba(251, 179, 101, 1);
}
.yellow {
  background-color: rgba(234, 217, 154, 1);
}
.purple {
  background-color: rgba(187, 185, 213, 1);
}
.red {
  background-color: rgba(177, 71, 84, 1);
}
.white {
  background-color: rgba(245, 244, 240, 1);
}

/*sizes*/
.sizes {
  display: flex;
  flex-wrap: wrap;
}

.sizes .rad-text {
  margin-left: 3px;
}

.sizes li {
  margin: 0 5px 5px 0;
}
.border-bottom {
  border-bottom: 1px solid #e4f1e3;
}

/*list product*/
.item-product {
  display: flex;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 3px 1px #f4f6f9;
  margin-bottom: 12px;
  margin-left: 0;
  margin-right: 0;
  padding: 12px 0;
}
.item-product img {
  width: 100%;
}

ul {
  list-style: none;
}
.available-color ul,
.available-size ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.available-color span {
  display: inline-block;
}
.available-color ul li {
  margin: 2px;
  width: 15px;
  height: 15px;
}
.available-size ul li span {
  border: 1px solid #b0b0b052;
  width: 26px;
  height: 26px;
  display: inline-block;
  text-align: center;
}
.available-color,
.available-size,
.insoles {
  margin-bottom: 12px;
}
.range-price {
  font-size: 20px;
  margin-bottom: 12px;
}

.ttl_product {
  font-size: 21px;
  font-weight: bold;
}

.orfer-info {
  font-size: 13px;
  background: #ffc107;
  padding: 1px 4px;
  border-radius: 3px;
}
.currency {
  margin-left: -4px;
}
.ttle-section {
  font-weight: bold;
}
/*insoles*/
.insoles ul {
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0;
}

.insoles ul li {
  width: 85px;
  height: 70px;
  text-align: center;
  border-top: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 2px;
}
.insoles ul li:last-child {
  border-right: 1px solid #e6e6e6;
}

.insoles img {
  width: 30%;
}

.ttl-insole {
  line-height: 20px;
  font-size: 14px;
}
.mt-6 {
  margin-top: 4rem;
}
nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  position: fixed;
  width: 80.5%;
  transition: 0.5s;
}

.sidebar-collapse
  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  width: 94%;
}

.img-size-50 {
  height: 50px;
  object-fit: contain;
  background: white;
}
.list-parametr .item-parametr {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.list-parametr .item-parametr span {
  padding: 0 2px;
}
.btn-green {
  background: #84c897;
  color: white;
  font-weight: bold;
  transition: 0.5s;
}
.btn-green:hover {
  background: #59b171;
}
.pre-total {
  padding: 0 20px;
  font-size: 15px;
  background: #f4f6f9;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  margin-bottom: -6px;
}
.pre-total .ttl-subtotal {
  float: left;
}
.pre-total .subtotal {
  float: right;
}
</style>

<style lang="scss">
.left-empty-space {
  display: block;
  @media (max-width: 575px) {
  }
}
</style>
